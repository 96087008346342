:root {
  --PRIMARY_BLUE: #0091FF;
}

@font-face {
  font-family: "Myriad-Pro Light";
  font-weight: normal;
  src: url("assets/MyriadPro-Light.otf") format("opentype");
}

@font-face {
  font-family: "Myriad-Pro Regular";
  font-weight: inherit;
  src: url("assets/MYRIADPRO-REGULAR.OTF") format("opentype");
}

@font-face {
  font-family: "Myriad-Pro Bold";
  font-weight: inherit;
  src: url("assets/MYRIADPRO-BOLDCOND.OTF") format("opentype");
}

.App {
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  /* overflow: hidden; */
}
